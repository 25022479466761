import React, { useState, useRef, useCallback, useEffect } from 'react';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import TipsPanel from './TipsPanel';
import { useResources } from './ResourceProvider';

const Chat = ({ coach, user, messageRefs }) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isTipsPanelOpen, setIsTipsPanelOpen] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);

  const abortControllerRef = useRef(null);
  const streamInProgressRef = useRef(false);
  const [streamedContent, setStreamedContent] = useState('');

  const { getSubscription, fetchSubscription, isLoadingSubscription } = useResources();
  const hasCheckedSubscriptionRef = useRef(false);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/chat/${coach.id}/history`,
          {
            headers: {
              'X-User-ID': user.id.toString()
            }
          }
        );

        if (!response.ok) throw new Error('Failed to fetch history');

        const history = await response.json();
        setMessages(history);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchHistory();
  }, [coach.id, user.id]);

  const handleSendMessage = useCallback(async (messageText) => {
    if (streamInProgressRef.current) {
      return;
    }

    try {
      streamInProgressRef.current = true;
      setIsWaitingForResponse(true);
      setError(null);

      // Pobierz strefę czasową użytkownika
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Dodaj wiadomość użytkownika
      setMessages(prev => [...prev, {
        role: 0, // user
        content: messageText,
        timestamp: new Date().toISOString(),
        isComplete: true
      }]);

      // Dodaj pustą wiadomość asystenta
      setMessages(prev => [...prev, {
        role: 1, // assistant
        content: '',
        timestamp: new Date().toISOString(),
        isComplete: false
      }]);

      let accumulatedContent = '';

      // Wyślij żądanie do API
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/chat/${coach.id}/stream`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-User-ID': user.id.toString()
          },
          body: JSON.stringify({
            message: messageText,
            timezone: userTimezone
          })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to get response from server');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        for (const line of lines) {
          if (!line.trim()) continue;

          try {
            const parsed = JSON.parse(line);

            if (parsed.event === 'complete') {
              setMessages(prev =>
                prev.map((msg, i) =>
                  i === prev.length - 1 ? {
                    ...msg,
                    content: accumulatedContent,
                    isComplete: true
                  } : msg
                )
              );
              streamInProgressRef.current = false;
              setIsWaitingForResponse(false);
              break;
            } else if (parsed.data) {
              accumulatedContent += parsed.data;

              // Aktualizuj wiadomość asystenta
              setMessages(prev =>
                prev.map((msg, i) =>
                  i === prev.length - 1 ? {
                    ...msg,
                    content: accumulatedContent,
                    isComplete: false
                  } : msg
                )
              );
            }
          } catch (e) {
            console.warn('Failed to parse line:', line);
          }
        }
      }
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Stream aborted');
        return;
      }
      console.error('Error in handleSendMessage:', err);
      setError(err.message);
    } finally {
      streamInProgressRef.current = false;
      setIsWaitingForResponse(false);
    }
  }, [coach.id, user.id]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const handleOpenTips = useCallback(() => {
    setIsTipsPanelOpen(!isTipsPanelOpen);
    if (!hasCheckedSubscriptionRef.current) {
      hasCheckedSubscriptionRef.current = true;
      fetchSubscription(coach.id);
    }
  }, [coach.id, fetchSubscription, isTipsPanelOpen]);

  const tips = messages.filter(msg => {
    const isValid = msg.notification_id && (msg.role === 1 || msg.role === '1');
    return isValid;
  });

  return (
    <div className="flex-1 flex flex-col h-full bg-gray-900">
      <div className="flex-1 flex flex-col h-full overflow-hidden relative">
        {isLoading ? (
          <div className="flex-1 flex items-center justify-center">
            <div className="text-center">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500 mx-auto"></div>
              <p className="mt-4 text-gray-400">Loading chat history...</p>
            </div>
          </div>
        ) : (
          <MessageList
            messages={messages}
            userId={user.id}
            coach={coach}
            messageRefs={messageRefs}
          />
        )}

        <div className="bg-gray-900 px-6 py-4 z-[60]">
          <MessageInput
            onSend={handleSendMessage}
            disabled={isLoading || streamInProgressRef.current || !!error}
            error={error}
            onOpenTips={handleOpenTips}
            isTipsPanelOpen={isTipsPanelOpen}
            coach={coach}
            isWaitingForResponse={isWaitingForResponse}
          />
        </div>

        {!isLoading && (
          <TipsPanel
            isOpen={isTipsPanelOpen}
            onClose={() => setIsTipsPanelOpen(false)}
            tips={tips}
            hasSubscription={getSubscription(coach.id)}
            isLoading={isLoadingSubscription(coach.id)}
            user={user}
            coach={coach}
            onTipClick={(tip, options) => {
              const messageRef = messageRefs.current.get(tip.notification_id);
              if (messageRef) {
                messageRef.scrollIntoView();
                if (options?.playAudio) {
                  setTimeout(() => {
                    messageRef.playAudio();
                  }, 500);
                }
              }
            }}
            onAskForTips={() => {
              handleSendMessage("Apparently you can send me daily tips and advice? I'd be happy to take advantage of that!");
              setIsTipsPanelOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default React.memo(Chat);