import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { User, LogOut, ChevronDown } from 'lucide-react';

const UserMenu = ({ user, onLogout }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) &&
          buttonRef.current && !buttonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const menu = isOpen && createPortal(
    <div
      ref={menuRef}
      className="fixed bg-gray-800 rounded-lg shadow-lg py-1 z-[60]"
      style={{
        width: '192px', // w-48 = 12rem = 192px
        top: buttonRef.current?.getBoundingClientRect().bottom + 8,
        right: window.innerWidth - (buttonRef.current?.getBoundingClientRect().right ?? 0)
      }}
    >
      <div className="px-4 py-2 border-b border-gray-700">
        <p className="text-sm font-medium text-gray-300">{user.name}</p>
        <p className="text-xs text-gray-400 truncate">{user.email}</p>
      </div>
      <button
        onClick={onLogout}
        className="w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-gray-700
          flex items-center space-x-2"
      >
        <LogOut className="h-4 w-4" />
        <span>Logout</span>
      </button>
    </div>,
    document.body
  );

  return (
    <>
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center space-x-2 text-gray-300 hover:text-white
          focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
          focus:ring-offset-gray-900 rounded-lg px-2 py-1"
      >
        <User className="h-5 w-5 text-purple-400" />
        <span className="text-sm">{user.name}</span>
        <ChevronDown className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {menu}
    </>
  );
};

export default UserMenu;