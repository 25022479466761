import React from 'react';
import { Bot } from 'lucide-react';

const WelcomeScreen = ({ isLoading, user }) => {
  return (
    <div className="flex-1 flex items-center justify-center bg-gray-900 p-4">
      <div className="max-w-md w-full text-center">
        <div className="mx-auto h-16 w-16 bg-gray-800 rounded-full flex items-center justify-center">
          <Bot className="h-8 w-8 text-purple-400" />
        </div>

        <h1 className="mt-6 text-3xl font-bold text-white">
          Welcome{user ? ` ${user.name}` : ''} to SimCoach
        </h1>

        <p className="mt-3 text-gray-400 text-lg">
          {isLoading
            ? "Loading available coaches..."
            : "Select a coach from the sidebar to start"}
        </p>

        {isLoading && (
          <div className="mt-6 flex justify-center">
            <div className="animate-spin rounded-full h-8 w-8 border-4 border-purple-400 border-t-purple-800" />
          </div>
        )}
      </div>
    </div>
  );
};

export default WelcomeScreen;