import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { Play, Pause } from 'lucide-react';

const AudioWaveform = forwardRef(({ audioUrl, userId }, ref) => {
  const containerRef = useRef(null);
  const wavesurferRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const audioDataRef = useRef(null);
  const abortControllerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    play: () => {
      if (wavesurferRef.current && isReady) {
        wavesurferRef.current.stop();
        wavesurferRef.current.play();
      }
    },
    pause: () => {
      if (wavesurferRef.current && isReady) {
        wavesurferRef.current.pause();
      }
    }
  }));

  useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: containerRef.current,
      height: 48,
      waveColor: '#A78BFA',
      progressColor: '#7C3AED',
      cursorColor: '#A78BFA',
      barWidth: 2,
      barGap: 1,
      barRadius: 3,
      responsive: true,
      normalize: true,
      backend: 'WebAudio',
      hideScrollbar: true,
      interact: true,
    });

    wavesurferRef.current = wavesurfer;

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    const headers = new Headers({
      'X-User-ID': userId.toString()
    });

    fetch(audioUrl, {
      headers,
      signal: abortControllerRef.current.signal
    })
      .then(response => {
        if (!response.ok) throw new Error('Failed to load audio');
        return response.blob();
      })
      .then(blob => {
        if (audioDataRef.current) {
          URL.revokeObjectURL(audioDataRef.current);
        }
        audioDataRef.current = URL.createObjectURL(blob);
        if (wavesurferRef.current) {
          wavesurferRef.current.load(audioDataRef.current);
        }
      })
      .catch(err => {
        if (err.name === 'AbortError') {
          console.log('Fetch aborted');
          return;
        }
        setError('Could not load audio file');
        setIsLoading(false);
      });

    wavesurfer.on('ready', () => {
      setIsLoading(false);
      setIsReady(true);
    });

    wavesurfer.on('error', (err) => {
      setError('Error processing audio file');
      setIsLoading(false);
    });

    wavesurfer.on('play', () => setIsPlaying(true));
    wavesurfer.on('pause', () => setIsPlaying(false));
    wavesurfer.on('finish', () => setIsPlaying(false));

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (audioDataRef.current) {
        URL.revokeObjectURL(audioDataRef.current);
      }
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [audioUrl, userId]);

  const togglePlayPause = () => {
    if (wavesurferRef.current && isReady) {
      wavesurferRef.current.playPause();
    }
  };

  if (error) {
    return (
      <div className="text-red-400 text-sm p-2">
        {error}
      </div>
    );
  }

  return (
    <div className="rounded-lg bg-gray-800 p-4 space-y-2 relative z-0">
      <div className="flex items-center space-x-4">
        <button
          onClick={togglePlayPause}
          disabled={isLoading || !isReady}
          className="p-2 rounded-full hover:bg-gray-700 transition-colors disabled:opacity-50"
        >
          {isPlaying ? (
            <Pause className="h-5 w-5 text-white" />
          ) : (
            <Play className="h-5 w-5 text-white" />
          )}
        </button>

        <div className="flex-1 relative">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center">
              <div className="animate-pulse">
                <div className="h-1 w-full bg-gray-700 rounded"></div>
              </div>
            </div>
          )}
          <div ref={containerRef} className="w-full" />
        </div>
      </div>
    </div>
  );
});

AudioWaveform.displayName = 'AudioWaveform';

export default React.memo(AudioWaveform);