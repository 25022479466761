import React, { useEffect, useRef } from 'react';
import Message from './Message';

const MessageList = ({ messages, userId, coach, messageRefs }) => {
  const messagesEndRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const lastMessageLengthRef = useRef(0);

  const scrollToBottom = (behavior = 'smooth') => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior, block: 'end' });
    }
  };

  // Initial scroll when component mounts
  useEffect(() => {
    scrollToBottom('instant');
  }, []);

  // Scroll on new messages or content changes
  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    const lastMessageContent = lastMessage?.content || '';

    if (messages.length > 0 && (
      messages.length !== lastMessageLengthRef.current ||
      lastMessageContent.length !== lastMessageLengthRef.current
    )) {
      scrollToBottom();
      lastMessageLengthRef.current = messages.length;
    }
  }, [messages]);

  useEffect(() => {
    const currentRefs = messageRefs?.current;
    return () => {
      if (currentRefs) {
        currentRefs.clear();
      }
    };
  }, [messageRefs]);

  return (
    <div className="flex-1 overflow-y-auto py-4" ref={scrollContainerRef}>
      {/* Regular chat messages */}
      {messages.map((message, index) => (
        <Message
          key={message.id || `message-${message.timestamp}-${index}`}
          ref={message.notification_id
            ? (ref) => {
                if (messageRefs?.current) {
                  if (ref) {
                    messageRefs.current.set(message.notification_id, ref);
                  } else {
                    messageRefs.current.delete(message.notification_id);
                  }
                }
              }
            : undefined}
          message={message}
          isLast={index === messages.length - 1}
          userId={userId}
          coach={coach}
        />
      ))}

      {/* Invisible element for scrolling */}
      <div ref={messagesEndRef} />
    </div>
  );
};

export default React.memo(MessageList);