import React, { createContext, useContext, useCallback, useState, useMemo } from 'react';

// Context for managing resources (subscriptions)
const ResourceContext = createContext(null);

// Cache implementation
class ResourceCache {
  constructor(ttl = 5 * 60 * 1000) { // 5 minutes default TTL
    this.cache = new Map();
    this.ttl = ttl;
  }

  get(key) {
    const item = this.cache.get(key);
    if (!item) return null;
    if (Date.now() > item.expiry) {
      this.cache.delete(key);
      return null;
    }
    return item.value;
  }

  set(key, value) {
    this.cache.set(key, {
      value,
      expiry: Date.now() + this.ttl
    });
  }

  clear() {
    this.cache.clear();
  }
}

// Resource Provider Component
export const ResourceProvider = ({ children, user }) => {
  const [subscriptions, setSubscriptions] = useState(new Map());
  const [loadingSubscriptions, setLoadingSubscriptions] = useState(new Set());
  const subscriptionCache = useMemo(() => new ResourceCache(), []);

  // Fetch subscription status
  const fetchSubscription = useCallback(async (coachId) => {
    if (!user || loadingSubscriptions.has(coachId)) return null;

    const cacheKey = `subscription-${coachId}-${user.id}`;
    const cached = subscriptionCache.get(cacheKey);
    if (cached !== null) return cached;

    setLoadingSubscriptions(prev => {
      const newSet = new Set(prev);
      newSet.add(coachId);
      return newSet;
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/subscription/${coachId}`,
        {
          headers: {
            'X-User-ID': user.id.toString()
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch subscription');

      const data = await response.json();
      const hasSubscription = data.has_subscription;

      subscriptionCache.set(cacheKey, hasSubscription);
      setSubscriptions(prev => new Map(prev).set(coachId, hasSubscription));

      return hasSubscription;
    } catch (error) {
      console.error('Error fetching subscription:', error);
      return null;
    } finally {
      setLoadingSubscriptions(prev => {
        const newSet = new Set(prev);
        newSet.delete(coachId);
        return newSet;
      });
    }
  }, [user, subscriptionCache]);

  // Clear all caches
  const clearCaches = useCallback(() => {
    subscriptionCache.clear();
    setSubscriptions(new Map());
    setLoadingSubscriptions(new Set());
  }, [subscriptionCache]);

  const contextValue = useMemo(() => ({
    // Subscription methods
    getSubscription: (coachId) => subscriptions.get(coachId),
    isLoadingSubscription: (coachId) => loadingSubscriptions.has(coachId),
    fetchSubscription,

    // Cache management
    clearCaches
  }), [
    subscriptions,
    loadingSubscriptions,
    fetchSubscription,
    clearCaches
  ]);

  return (
    <ResourceContext.Provider value={contextValue}>
      {children}
    </ResourceContext.Provider>
  );
};

// Hook for using resources
export const useResources = () => {
  const context = useContext(ResourceContext);
  if (!context) {
    throw new Error('useResources must be used within a ResourceProvider');
  }
  return context;
};