import React, { useState, useRef, useEffect } from 'react';
import { Bot, ChevronLeft, ChevronRight, X } from 'lucide-react';

const CoachButton = ({ coach, isSelected, onClick, isCollapsed }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef(null);

  const updateTooltipPosition = () => {
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setTooltipPosition({
        x: rect.right + 8,
        y: rect.top + (rect.height / 2)
      });
    }
  };

  useEffect(() => {
    if (showTooltip) {
      updateTooltipPosition();
      window.addEventListener('scroll', updateTooltipPosition);
      return () => window.removeEventListener('scroll', updateTooltipPosition);
    }
  }, [showTooltip]);

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={onClick}
        onMouseEnter={() => isCollapsed && setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="w-full block focus:outline-none"
      >
        <div className={`w-full flex items-center rounded-lg border border-transparent
          ${isCollapsed ? 'justify-center p-1' : 'p-2 space-x-3'}
          ${isSelected
            ? 'text-purple-400 bg-purple-500/10 border-purple-500/20'
            : 'text-gray-300 hover:bg-gray-800 hover:text-white'}`}
        >
          {coach.avatar_url ? (
            <img
              src={coach.avatar_url}
              alt={`${coach.name}'s avatar`}
              className={`object-cover flex-shrink-0 rounded-full
                ${isCollapsed ? 'h-5 w-5' : 'h-6 w-6'}`}
            />
          ) : (
            <Bot className={`flex-shrink-0 ${isCollapsed ? 'h-5 w-5' : 'h-6 w-6'}`} />
          )}
          {!isCollapsed && (
            <div className="flex-1 text-left">
              <p className="font-medium truncate">{coach.name}</p>
            </div>
          )}
        </div>
      </button>

      {showTooltip && isCollapsed && (
        <div
          className="fixed z-[9999] pointer-events-none"
          style={{
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
            transform: 'translateY(-50%)'
          }}
        >
          <div className="bg-gray-800 text-gray-100 text-sm rounded-md shadow-lg
            border border-gray-700 px-3 py-1.5 whitespace-nowrap relative">
            <div className="absolute right-full top-1/2 -translate-y-1/2 border-[6px]
              border-transparent border-r-gray-800"
              style={{
                filter: 'drop-shadow(-3px 0 1px rgba(0,0,0,0.1))',
                marginRight: '-1px'
              }}
            />
            {coach.name}
          </div>
        </div>
      )}
    </div>
  );
};

const Sidebar = ({
  coaches,
  selectedCoach,
  onSelectCoach,
  isLoading,
  isCollapsed,
  onToggleCollapse,
  onClose,
  onLogout,
  user
}) => {
  return (
    <div className={`h-full bg-gray-900 border-r border-gray-800 flex flex-col relative
      ${isCollapsed ? 'w-12' : 'w-80'} transition-all duration-300`}>
      {/* Header */}
      <div className={`h-14 flex items-center
        ${isCollapsed ? 'justify-center px-2' : 'justify-between px-4'}`}>
        <button
          onClick={() => onSelectCoach(null)}
          className={`text-white hover:text-purple-400 transition-colors
            ${isCollapsed ? 'hidden' : 'text-xl font-semibold'}`}
        >
          {!isCollapsed && 'SimCoach'}
        </button>

        {/* Desktop collapse button */}
        {onToggleCollapse && (
          <button
            onClick={onToggleCollapse}
            className="flex items-center justify-center p-2 rounded-lg text-gray-400
              hover:text-white hover:bg-gray-800 transition-colors w-8 h-8"
          >
            {isCollapsed ? (
              <ChevronRight className="h-5 w-5" />
            ) : (
              <ChevronLeft className="h-5 w-5" />
            )}
          </button>
        )}

        {/* Mobile close button */}
        {onClose && (
          <button
            onClick={onClose}
            className="md:hidden p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-800 transition-colors"
          >
            <X className="h-5 w-5" />
          </button>
        )}
      </div>

      {/* Coaches list */}
      <div className="flex-1 overflow-y-auto py-2">
        {!isCollapsed && (
          <h2 className="text-xs font-semibold text-gray-500 uppercase tracking-wider mb-3 px-4">
            Available Coaches
          </h2>
        )}

        {isLoading ? (
          <div className="space-y-1 px-2">
            {[1, 2, 3].map((n) => (
              <div
                key={n}
                className={`animate-pulse flex items-center p-2 rounded-lg
                  ${isCollapsed ? 'justify-center' : 'space-x-3'}`}
              >
                <div className="h-8 w-8 bg-gray-800 rounded-full"></div>
                {!isCollapsed && (
                  <div className="flex-1">
                    <div className="h-4 bg-gray-800 rounded w-3/4"></div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="space-y-1 px-2">
            {coaches.map((coach) => (
              <CoachButton
                key={coach.id}
                coach={coach}
                isSelected={selectedCoach?.id === coach.id}
                onClick={() => onSelectCoach(coach)}
                isCollapsed={isCollapsed}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Sidebar);