import React, { useState, useEffect, useRef } from 'react';
import { Lightbulb } from 'lucide-react';

const MessageInput = ({ onSend, disabled, error, onOpenTips, isTipsPanelOpen, coach, isWaitingForResponse }) => {
  const [message, setMessage] = useState('');
  const [visibleError, setVisibleError] = useState(null);
  const textareaRef = useRef(null);
  const errorTimeoutRef = useRef(null);
  const errorDisplayTimeoutRef = useRef(null);

  useEffect(() => {
    if (error) {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      if (errorDisplayTimeoutRef.current) {
        clearTimeout(errorDisplayTimeoutRef.current);
      }

      if (error.includes('Not connected') || error.includes('Connection timeout')) {
        errorDisplayTimeoutRef.current = setTimeout(() => {
          setVisibleError(error);
        }, 3000);
      } else {
        setVisibleError(error);
      }

      if (error.includes('connection') || error.includes('connect')) {
        errorTimeoutRef.current = setTimeout(() => {
          setVisibleError(null);
        }, 30000);
      }
    } else {
      setVisibleError(null);
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      if (errorDisplayTimeoutRef.current) {
        clearTimeout(errorDisplayTimeoutRef.current);
      }
    }

    return () => {
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
      if (errorDisplayTimeoutRef.current) {
        clearTimeout(errorDisplayTimeoutRef.current);
      }
    };
  }, [error]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!message.trim() || disabled) return;
    onSend(message.trim());
    setMessage('');
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const getPlaceholderText = () => {
    if (isWaitingForResponse) return `Waiting for ${coach?.name || 'assistant'}...`;
    if (disabled) return "Connecting...";
    return "Type your message...";
  };

  return (
    <div className="relative max-w-2xl mx-auto w-full">
      {visibleError && (
        <div className="absolute bottom-full mb-2 left-0 right-0 px-4 py-2 bg-red-500 bg-opacity-10 border border-red-500 rounded-lg">
          <p className="text-red-400 text-sm">{visibleError}</p>
        </div>
      )}
      <div className="flex gap-2 items-start">
        <div className="flex-1 relative">
          <textarea
            ref={textareaRef}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder={getPlaceholderText()}
            readOnly={disabled}
            rows={1}
            className={`block w-full rounded-lg border-0 py-3 pr-16 bg-gray-800 text-gray-100
              ring-1 ring-inset ring-gray-700 placeholder:text-gray-500
              focus:ring-2 focus:ring-inset focus:ring-purple-500
              ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
              resize-none max-h-48`}
          />
          <button
            type="submit"
            onClick={handleSubmit}
            disabled={!message.trim() || disabled}
            className={`absolute right-2 bottom-2 p-2 rounded-full
              ${message.trim() && !disabled
                ? 'text-purple-400 hover:bg-gray-700'
                : 'text-gray-600 cursor-not-allowed'
              }`}
          >
            <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
            </svg>
          </button>
        </div>

        <button
          onClick={onOpenTips}
          className={`flex items-center space-x-2 px-3 h-[42px] rounded-lg transition-colors
            ${isTipsPanelOpen
              ? 'bg-gray-800 text-white'
              : 'text-gray-300 hover:text-white hover:bg-gray-800'
            }`}
        >
          <Lightbulb className="h-5 w-5" />
          <span>Tips</span>
        </button>
      </div>
    </div>
  );
};

export default React.memo(MessageInput);