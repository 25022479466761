// Message.jsx
import React, { useRef } from 'react';
import { Bot, User2 } from 'lucide-react';
import AudioWaveform from './AudioWaveform';
import StreamedAudioPlayer from './StreamedAudioPlayer';
import { format } from 'date-fns';
import StreamingMessage from './StreamingMessage';

const Message = React.forwardRef(({ message, isLast, userId, coach }, ref) => {
  const waveformRef = useRef(null);
  const isAssistant = message.role === 1 || message.role === '1';
  const isTip = !!message.notification_id;

  React.useImperativeHandle(ref, () => ({
    playAudio: () => {
      if (waveformRef.current) {
        waveformRef.current.play();
      }
    },
    scrollIntoView: () => {
      const element = document.querySelector(`[data-tip-id="${message.notification_id}"]`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }));

  return (
    <div
      className={`flex w-full space-x-3 max-w-2xl mx-auto p-4 ${
        isAssistant ? 'justify-start' : 'justify-end'
      }`}
      {...(isTip ? { 'data-tip-id': message.notification_id } : {})}
    >
      {isAssistant && (
        <div className="flex-shrink-0">
          {coach?.avatar_url ? (
            <img
              src={coach.avatar_url}
              alt={`${coach.name}'s avatar`}
              className="h-10 w-10 rounded-full object-cover"
            />
          ) : (
            <Bot className="h-10 w-10 text-purple-400" />
          )}
        </div>
      )}

      <div className={`flex-1 ${isAssistant ? 'mr-12' : 'ml-12'}`}>
        <div className={`rounded-lg p-4 space-y-3 relative ${
          isAssistant
            ? 'bg-gray-800 text-gray-100'
            : 'bg-purple-600 text-white'
        }`}>
          {isTip && (
            <div className="absolute -top-3 right-3">
              <span className="px-3 py-1 text-sm font-semibold rounded-md
                bg-purple-500 text-white shadow-md">
                Tip
              </span>
            </div>
          )}

          {isAssistant && !message.content && !message.isComplete && (
            <div className="flex items-center space-x-2 text-gray-400">
              <div className="animate-spin rounded-full h-4 w-4 border-2 border-t-transparent border-purple-500"></div>
              <span>Thinking...</span>
            </div>
          )}

          {message.content && (
            <div className="space-y-3">
              <div className={`markdown ${
                isAssistant
                  ? 'text-gray-100'
                  : 'text-white'
              }`}>
                <StreamingMessage
                  content={message.content}
                  isComplete={message.isComplete}
                />
              </div>

              {isAssistant && !isTip && message.isComplete && (
                <div className="mt-2 -mb-2">
                  <StreamedAudioPlayer text={message.content} userId={userId} coach={coach} />
                </div>
              )}
            </div>
          )}

          {isTip && message.audio_file && (
            <div className="mt-3">
              <AudioWaveform
                ref={waveformRef}
                audioUrl={`${process.env.REACT_APP_API_URL}/api/audio/${message.notification_id}`}
                userId={userId}
              />
            </div>
          )}
          <div className={`text-xs mt-2 ${
            isAssistant ? 'text-gray-500' : 'text-purple-200'
          }`}>
            {format(new Date(message.timestamp), 'MMM d, yyyy • h:mm a')}
          </div>
        </div>
      </div>

      {!isAssistant && (
        <div className="flex-shrink-0">
          <User2 className="h-10 w-10 text-purple-400" />
        </div>
      )}
    </div>
  );
});

Message.displayName = 'Message';

export default React.memo(Message);