import React, { useState, useEffect } from 'react';
import { X, Play, Calendar } from 'lucide-react';
import { format } from 'date-fns';

const TipsPanel = ({
  isOpen,
  onClose,
  tips = [],
  onTipClick,
  onAskForTips,
  hasSubscription,
  isLoading,
  user,
  coach
}) => {
  const [invitationSent, setInvitationSent] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [notificationTime, setNotificationTime] = useState(null);

  useEffect(() => {
    const fetchNotificationTime = async () => {
      if (!user || !coach) return;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/notification-time/${coach.id}`,
          {
            headers: {
              'X-User-ID': user.id.toString()
            }
          }
        );

        if (response.ok) {
          const data = await response.json();
          setNotificationTime(data.notification_time);
        }
      } catch (error) {
        console.error('Error fetching notification time:', error);
      }
    };

    if (hasSubscription) {
      fetchNotificationTime();
    }
  }, [hasSubscription, user, coach]);

  const handleMessageClick = (tip) => {
    if (onTipClick) {
      onTipClick(tip, { playAudio: false });
    }
  };

  const handlePlayClick = (e, tip) => {
    e.stopPropagation();
    if (onTipClick) {
      onTipClick(tip, { playAudio: true });
    }
  };

  const handleResendInvitation = async () => {
    if (!user || !coach || isResending) return;

    setIsResending(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/resend-calendar-invitation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-ID': user.id.toString()
        },
        body: JSON.stringify({ coach_id: coach.id })
      });

      if (response.ok) {
        setInvitationSent(true);
      }
    } catch (error) {
      console.error('Failed to resend invitation:', error);
    } finally {
      setIsResending(false);
    }
  };

const renderContent = () => {
    if (isLoading) {
      return (
        <div className="p-4 flex justify-center items-center">
          <div className="animate-spin rounded-full h-8 w-8 border-4 border-purple-500 border-t-transparent"/>
        </div>
      );
    }

    if (!hasSubscription) {
      return (
        <div className="p-4 space-y-4">
          <p className="text-gray-300 text-sm">
            Did you know that your coach can send you daily tips and advice?
            All you have to do is ask for it!
          </p>
          <button
            onClick={() => onAskForTips()}
            className="w-full px-4 py-2 bg-purple-600 text-white rounded-lg
              hover:bg-purple-700 transition-colors"
          >
            Ask for daily tips
          </button>
        </div>
      );
    }

    const hasActiveNotifications = tips.some(tip => tip.notification_id);
    if (hasSubscription && !hasActiveNotifications) {
      return (
        <div className="p-4">
          <p className="text-gray-300">
            Congratulations, you will soon get your first message from me! We have scheduled you to receive messages every day at {notificationTime || '12:00'}.
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="p-4 border-b border-gray-800">
          <button
            onClick={handleResendInvitation}
            disabled={isResending || invitationSent}
            className={`flex items-center space-x-2 ${
              isResending || invitationSent
                ? 'text-gray-500 cursor-not-allowed'
                : 'text-purple-400 hover:text-purple-300'
            }`}
          >
            <Calendar className="h-4 w-4" />
            <span>
              {isResending ? 'Sending...' : invitationSent ? 'Invitation sent' : 'Resend calendar invitation'}
            </span>
          </button>
        </div>

        <div>
          {[...tips].reverse().map((tip) => (
            <div
              key={tip.notification_id}
              className="p-4 border-b border-gray-800 hover:bg-gray-800/50
                cursor-pointer transition-colors"
              onClick={() => handleMessageClick(tip)}
            >
              <div className="text-sm text-gray-500 mb-2">
                {format(new Date(tip.timestamp), 'MMMM d, yyyy')}
              </div>

              <p className="text-gray-300 text-sm line-clamp-2">
                {tip.content.split(' ').slice(0, 15).join(' ')}...
              </p>

              {tip.audio_file && (
                <button
                  onClick={(e) => handlePlayClick(e, tip)}
                  className="mt-2 flex items-center text-purple-400 hover:text-purple-300
                    focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2
                    focus:ring-offset-gray-900 rounded-lg px-2 py-1"
                >
                  <Play className="h-4 w-4 mr-1" />
                  <span className="text-xs">Play audio</span>
                </button>
              )}
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div
      className={`absolute right-0 top-0 bottom-0 w-80 bg-gray-900 border-l border-gray-800
        transform transition-transform duration-300 ease-in-out z-50
        ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}
    >
      <div className="h-14 flex items-center justify-between px-4 border-b border-gray-800">
        <h2 className="text-lg font-semibold text-white">Tips</h2>
        <button
          onClick={onClose}
          className="p-1 rounded-lg text-gray-400 hover:text-white
            hover:bg-gray-800 transition-colors"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="overflow-y-auto h-[calc(100%-3.5rem)]">
        {renderContent()}
      </div>
    </div>
  );
};

export default React.memo(TipsPanel);