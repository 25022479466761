import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BrowserRouter, Routes, Route, useNavigate, useParams } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Chat from './components/Chat';
import WelcomeScreen from './components/WelcomeScreen';
import LoginScreen from './components/LoginScreen';
import UserMenu from './components/UserMenu';
import { Menu } from 'lucide-react';
import { ResourceProvider } from './components/ResourceProvider';

// Main App wrapper with Router
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppContent />} />
        <Route path="/coach/:coachId" element={<AppContent />} />
      </Routes>
    </BrowserRouter>
  );
};

// Main application content
const AppContent = () => {
  const [coaches, setCoaches] = useState([]);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const [user, setUser] = useState(null);
  const messageRefs = useRef(new Map());
  const navigate = useNavigate();
  const { coachId } = useParams();

  const handleLogin = useCallback((userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  }, []);

  // Load user data on initial mount
  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
  }, []);

  // Fetch coaches when user is logged in
  useEffect(() => {
    if (!user) return;

    const fetchCoaches = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/coaches`, {
          headers: {
            'X-User-ID': user.id.toString()
          }
        });

        if (response.status === 401) {
          handleLogout();
          return;
        }

        if (!response.ok) {
          throw new Error('Failed to fetch coaches');
        }

        const data = await response.json();
        setCoaches(data);
        setIsLoading(false);

        // If coachId is provided in URL, select that coach
        if (coachId) {
          const coach = data.find(c => c.id === parseInt(coachId));
          if (coach) {
            setSelectedCoach(coach);
          }
        }
      } catch (error) {
        console.error('Error fetching coaches:', error);
        setError(error.message);
        setIsLoading(false);
      }
    };

    fetchCoaches();
  }, [user, coachId]);

  const handleSelectCoach = useCallback((coach) => {
    setSelectedCoach(coach);
    if (coach) {
      navigate(`/coach/${coach.id}`);
    } else {
      navigate('/');
    }
  }, [navigate]);

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    setSelectedCoach(null);
    navigate('/');
  };

  if (!user) {
    return <LoginScreen onLogin={handleLogin} />;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-900 flex items-center justify-center p-4">
        <div className="bg-gray-800 rounded-lg shadow-lg p-6 max-w-md w-full">
          <h2 className="text-red-400 text-xl font-semibold mb-2">Error</h2>
          <p className="text-gray-300">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <ResourceProvider user={user}>
      <div className="h-screen bg-gray-900 flex">
        {/* Desktop Sidebar */}
        <div className={`hidden md:block fixed top-0 bottom-0 left-0 z-30
          transition-all duration-300 ease-in-out
          ${isSidebarOpen ? 'w-80' : 'w-12'}`}
        >
          <Sidebar
            coaches={coaches}
            selectedCoach={selectedCoach}
            onSelectCoach={handleSelectCoach}
            isLoading={isLoading}
            isCollapsed={!isSidebarOpen}
            onToggleCollapse={() => setIsSidebarOpen(!isSidebarOpen)}
            user={user}
            onLogout={handleLogout}
          />
        </div>

        {/* Mobile Sidebar - Overlay */}
        <div
          className={`fixed inset-0 bg-gray-900 bg-opacity-50 z-40 transition-opacity md:hidden
            ${isMobileSidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
          onClick={() => setIsMobileSidebarOpen(false)}
        />

        {/* Mobile Sidebar */}
        <div
          className={`fixed inset-y-0 left-0 z-50 w-80 transform transition-transform duration-300 ease-in-out md:hidden
            ${isMobileSidebarOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <Sidebar
            coaches={coaches}
            selectedCoach={selectedCoach}
            onSelectCoach={setSelectedCoach}
            isLoading={isLoading}
            onClose={() => setIsMobileSidebarOpen(false)}
            user={user}
            onLogout={handleLogout}
          />
        </div>

        {/* Main content */}
        <main className={`flex-1 flex flex-col h-screen transition-all duration-300 ease-in-out
          ${isSidebarOpen ? 'md:ml-80' : 'md:ml-12'}`}>
          {/* Header */}
          <div className="sticky top-0 z-20 bg-gray-900 shadow-md">
            <div className="px-6 py-4 border-b border-gray-800">
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => setIsMobileSidebarOpen(true)}
                    className="md:hidden p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700"
                  >
                    <Menu className="h-6 w-6" />
                  </button>
                  <div className="flex items-center space-x-4">
                    <h1 className="text-lg font-semibold text-white hover:text-purple-400 transition-colors">
                      {selectedCoach ? `Chat with ${selectedCoach.name}` : 'Welcome to SimCoach'}
                    </h1>
                  </div>
                </div>

                <div className="flex items-center space-x-4">
                  <UserMenu user={user} onLogout={handleLogout} />
                </div>
              </div>
            </div>
          </div>

          {/* Chat or Welcome Screen */}
          <div className="flex-1 flex overflow-hidden">
            {selectedCoach ? (
              <Chat
                key={selectedCoach.id}
                coach={selectedCoach}
                user={user}
                messageRefs={messageRefs}
              />
            ) : (
              <WelcomeScreen isLoading={isLoading} user={user} />
            )}
          </div>
        </main>
      </div>
    </ResourceProvider>
  );
};

export default App;