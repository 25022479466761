// StreamingMessage.jsx
import React from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownComponents = {
  p: ({children}) => (
    <p className="whitespace-pre-wrap mb-4 last:mb-0">
      {children}
    </p>
  ),
  strong: ({children}) => (
    <strong className="font-bold">{children}</strong>
  ),
  em: ({children}) => (
    <em className="italic">{children}</em>
  ),
  h1: ({children}) => (
    <h1 className="text-2xl font-bold mb-4">{children}</h1>
  ),
  h2: ({children}) => (
    <h2 className="text-xl font-bold mb-3">{children}</h2>
  ),
  h3: ({children}) => (
    <h3 className="text-lg font-bold mb-2">{children}</h3>
  ),
  ul: ({children}) => (
    <ul className="list-disc pl-6 mb-4">{children}</ul>
  ),
  ol: ({children}) => (
    <ol className="list-decimal pl-6 mb-4">{children}</ol>
  ),
  li: ({children}) => (
    <li className="mb-2">{children}</li>
  ),
  code: ({children}) => (
    <code className="bg-gray-700 px-1 rounded">{children}</code>
  ),
  hr: () => (
    <hr className="my-6 border-gray-700" />
  ),
  blockquote: ({children}) => (
    <blockquote className="border-l-4 border-gray-500 pl-4 italic my-4">
      {children}
    </blockquote>
  ),
};

const StreamingMessage = ({ content, isComplete }) => (
  <ReactMarkdown components={MarkdownComponents}>
    {(content || '') + (!isComplete ? '▌' : '')}
  </ReactMarkdown>
);

export default React.memo(StreamingMessage);