import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, Loader } from 'lucide-react';
import WaveSurfer from 'wavesurfer.js';

const StreamedAudioPlayer = ({ text, userId, coach }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [audioUrl, setAudioUrl] = useState(null);
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const audioRef = useRef(new Audio());
  const waveformRef = useRef(null);
  const containerRef = useRef(null);
  const wavesurferRef = useRef(null);
  const abortControllerRef = useRef(null);

  const initWaveSurfer = (audioBlob) => {
    try {
      if (!containerRef.current) {
        console.error('Container ref is not ready');
        return;
      }

      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }

      const wavesurfer = WaveSurfer.create({
        container: containerRef.current,
        height: 48,
        waveColor: '#A78BFA',
        progressColor: '#7C3AED',
        cursorColor: '#A78BFA',
        barWidth: 2,
        barGap: 1,
        barRadius: 3,
        responsive: true,
        normalize: true,
        backend: 'WebAudio',
        hideScrollbar: true,
        interact: true,
      });

      wavesurferRef.current = wavesurfer;

    wavesurfer.on('ready', () => {
      setShowPlaceholder(false);
    });

          const url = URL.createObjectURL(audioBlob);
      setAudioUrl(url);

      wavesurfer.on('error', (err) => {
        console.error('WaveSurfer error:', err);
        setError('Error loading audio visualization');
      });

      wavesurfer.load(url);

      return () => {
        URL.revokeObjectURL(url);
        wavesurfer.destroy();
      };
    } catch (err) {
      console.error('Error initializing WaveSurfer:', err);
      setError('Error initializing audio visualization');
    }
  };

  const playAudio = async () => {
    if (!coach?.id) {
      setError('Coach information is missing');
      return;
    }

    if (!containerRef.current) {
      setError('Audio player is not ready');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      // Stop any existing playback
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      abortControllerRef.current = new AbortController();

      // If we already have the audio loaded in wavesurfer
      if (wavesurferRef.current && audioUrl) {
        wavesurferRef.current.play();
        setIsPlaying(true);
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tts/stream`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-User-ID': userId.toString()
        },
        body: JSON.stringify({
          text: text.trim(),
          coach_id: parseInt(coach.id)
        }),
        signal: abortControllerRef.current.signal
      });

      if (!response.ok) {
        throw new Error('Failed to stream audio');
      }

      // Get the audio data as a blob
      const audioBlob = await response.blob();

      // Initialize WaveSurfer with the complete audio
      const cleanup = initWaveSurfer(audioBlob);
      if (wavesurferRef.current) {
        wavesurferRef.current.on('ready', () => {
          wavesurferRef.current.play();
          setIsPlaying(true);
        });

        wavesurferRef.current.on('finish', () => {
          setIsPlaying(false);
        });

        wavesurferRef.current.on('play', () => {
          setIsPlaying(true);
        });

        wavesurferRef.current.on('pause', () => {
          setIsPlaying(false);
        });
      }

    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Playback cancelled');
        return;
      }
      console.error('Error playing audio:', err);
      setError('Error playing audio');
    } finally {
      setIsLoading(false);
    }
  };

  const stopAudio = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.pause();
      setIsPlaying(false);
    }
  };

  const togglePlayPause = async () => {
    if (isLoading) return;

    if (!wavesurferRef.current || !audioUrl) {
      await playAudio();
    } else {
      if (isPlaying) {
        wavesurferRef.current.pause();
      } else {
        wavesurferRef.current.play();
      }
    }
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, []);

  return (
    <div className="rounded-lg bg-gray-800 p-4 space-y-2 relative">
      <div className="flex items-center space-x-4">
        <button
          onClick={togglePlayPause}
          disabled={isLoading}
          className="p-2 rounded-full hover:bg-gray-700 transition-colors
            disabled:opacity-50 disabled:cursor-not-allowed text-white"
        >
          {isLoading ? (
            <Loader className="h-5 w-5 animate-spin" />
          ) : isPlaying ? (
            <Pause className="h-5 w-5" />
          ) : (
            <Play className="h-5 w-5" />
          )}
        </button>

        <div className="flex-1 relative">
          <div ref={containerRef} className="w-full h-12">
            {showPlaceholder && (
              <div className="absolute inset-0 h-12 flex items-center">
                <div className="w-full flex justify-around items-center">
                  {[...Array(20)].map((_, i) => (
                    <div
                      key={i}
                      className={`w-1 bg-purple-400 rounded-full transform transition-all duration-300
                        ${isPlaying ? 'animate-pulse' : 'h-2'}`}
                      style={{
                        height: isPlaying ? `${Math.random() * 24 + 8}px` : '8px',
                        animationDelay: `${i * 0.1}s`
                      }}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {error && (
        <div className="text-red-400 text-sm mt-2">
          {error}
        </div>
      )}
    </div>
  );
};

export default StreamedAudioPlayer;